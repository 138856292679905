import { axiosInstance } from "../../api/config";

export const getVendors = async () => {
  try {
    const response = await axiosInstance.get("/getverifiedvendoruser");
    return response;
  } catch (error) {
    throw error;
  }
}

export const addVendor = async (initialState, bank_account_details, Upload_company_document, vendorId = null) => {
  try {
    const requestData = {
      ...initialState,
      Upload_company_document,
      bank_account_details
    };

    const requestConfig = {
      headers: { "Content-Type": Upload_company_document instanceof File ? "multipart/form-data" : "application/json" },
    };

    const url = vendorId
      ? `/vendoruser/update_vendoruser/${vendorId}`
      : `/vendoruser/add_vendoruser`;

    const response = await axiosInstance[vendorId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verifyVenderOtp = async (email_id, otpVerify, setError) => {
  try {
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const response = await axiosInstance.post(`/vendoruserotpverify`, {
      email_id,
      otpVerify
    }, requestConfig);
    return response;
  } catch (error) {
    setError({ global: "Invalid Otp" });
    throw error;
  }
}

export const deleteVendor = async (vendorId) => {
  try {
    const response = await axiosInstance.delete(`/vendoruser/delete_vendoruser/${vendorId}`);
    return response;
  } catch (error) {
    throw error;
  }
}