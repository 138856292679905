import React from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TbUserEdit } from "react-icons/tb";
import { NoDataFound } from "../Common";
import { deleteVendor } from "../../utils/venderUtils/venderUtils";

const VendorsTable = ({ setOpen, setEditData, datas, setDatasFn }) => {
  const tableHeadings = ["Name", "Email", "Phone", "Edit", "Delete"];
  const delData = (vendorId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteVendor(vendorId)
      .then((res) => setDatasFn())
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex h-fit items-center w-full flex-col mt-5">
      <div className="flex flex-col w-full mt-3 max-h-screen ">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {datas.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          scope="col"
                          key={`${heading}-${index}`}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen  ">
                    {datas?.map((data, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {data?.vendor_company_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {data?.email_id || "N/A"}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 ">
                            {data?.phone_number || "N/A"}
                          </div>
                        </td>
                        <td className={`px-6 py-4 whitespace-nowrap `}>
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(data);
                              setOpen(true);
                            }}
                          >
                            <TbUserEdit />
                          </div>
                        </td>
                        <td className={`px-6 py-4 whitespace-nowrap `}>
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              delData(data?._id);
                            }}
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={" vendors "} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorsTable;
