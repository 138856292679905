import React, { useState } from "react";
import { TiArrowLeft } from "react-icons/ti";
import { OtpInput } from "../Common";
import { toast } from "react-toastify";
import { verifyVenderOtp } from "../../utils/venderUtils/venderUtils";

const VendorOtpFiled = ({
  response,
  setOtpSended,
  setDatasFn,
  setOpen,
  setEditData,
}) => {
  const [err, setError] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleOtpVerification = (otpVerify) => {
    if (!/^.{4}$/.test(otpVerify)) {
      setError({ global: "Invalid Otp" });
      return;
    }
    setError({ global: "" });
    setLoading(true);
    verifyVenderOtp(response?.email_id, otpVerify, setError)
      .then((res) => {
        setDatasFn();
        toast(`Vender Added`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setOpen(false);
      })
      .catch((err) => {
        console.error(err); 
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <div className="flex w-full h-full justify-center items-center flex-col gap-8 py-5">
        <label
          htmlFor="otpFields"
          className="text-base text-shadow-black text-white"
        >
          Verification code<span className="text-red-600 ml-1">*</span>
        </label>
        <div className="flex justify-center items-center gap-1.5">
          <OtpInput handleSubmit={handleOtpVerification} loading={loading} />
        </div>
        <span
          onClick={() => {
            setError({ global: "" });
            setOtpSended(false);
          }}
          className="w-fit flex gap-1 items-center justify-center text-white text-sm cursor-pointer "
        >
          <TiArrowLeft /> back
        </span>
        {err.global && (
          <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
            {err.global}
          </div>
        )}
      </div>
    </>
  );
};

export default VendorOtpFiled;
