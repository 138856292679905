import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Login, Vendors } from './pages';
import { AuthProtected, UserProtected } from "./components/ProtectedRoutes/UserProtected";

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Vendors/>} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
